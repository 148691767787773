<template>
  <v-container>
    <h1>New User Invite</h1>

    <v-row>
      <v-col :cols="12">
        <v-card tile>
          <v-card-text>
            <v-form
              ref="form"
              v-model="validForm"
            >
              <v-row>
                <v-col :cols="12" :md="4" class="pb-0">
                  <v-text-field
                    v-model="form.name"
                    :rules="[v => !!v || 'Full name is required']"
                    label="Full Name"
                    outlined
                    required
                    dense
                  />
                </v-col>
                <v-col :cols="12" :md="4" class="pb-0">
                  <v-text-field
                    v-model="form.email"
                    :rules="[v => !!v || 'Email address is required']"
                    label="Email Address"
                    outlined
                    required
                    dense
                    autofocus
                  />
                </v-col>
                <v-col :cols="12" :md="4" class="pb-0">
                  <v-select
                    v-model="form.account_id"
                    :items="accounts"
                    :rules="[v => !!v || 'Account is required']"
                    item-value="id"
                    item-text="name"
                    label="User Account"
                    outlined
                    required
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col :cols="12" :md="4" class="pb-0">
                  <v-select
                    v-model="form.role"
                    :items="roles"
                    :rules="[v => !!v || 'Role is required']"
                    item-value="name"
                    item-text="name"
                    label="User Role"
                    outlined
                    required
                    dense
                  />
                </v-col>
              </v-row>

              <FormErrors ref="formErrors" />

              <v-btn
                color="primary"
                depressed
                :loading="loading"
                @click="inviteUser"
              >
                Invite User
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <SnackBar success ref="successBar">
      User invited successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the user.
    </SnackBar>
  </v-container>
</template>

<script>
import HasForm from '@/mixins/HasForm'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'NewUser',

  mixins: [HasForm],

  components: {
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false,
      roles: [],
      accounts: []
    }
  },

  mounted () {
    this.getAccounts()
    this.getRoles()
  },

  methods: {
    inviteUser () {
      this.$api.post('users/invite', this.form)
        .then(() => {
          this.$refs.successBar.open()
          this.$router.push({ name: 'dashboard' })
        })
        .catch(error => {
          this.$refs.formErrors.setErrors(error)
          this.$refs.errorBar.open()
        })
        .then(() => this.loading = false)
    },

    getAccounts() {
      this.loading = true
      this.$api.get(`accounts/${this.$auth.account.id}/children`, {
        params: {
          limit: 100
        }
      })
        .then(({ data: accounts }) => {
          this.accounts = [
            ...accounts,
            ...[ this.$auth.account ]
          ]
          this.form.account_id = this.$auth.account.id
        })
        .finally(() => this.loading = false)
    },

    getRoles() {
      this.loading = true
      this.$api.get('customer-roles')
        .then(({ data: roles }) => {
          this.roles = roles
          this.form.role = roles[0] ? roles[0].name : null
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
